import { Component } from '@angular/core';

@Component({
  selector: 'app-origin-of-software-architecture',
  templateUrl: './origin-of-software-architecture.component.html',
  styleUrls: ['./origin-of-software-architecture.component.scss'],
})
export class OriginOfSoftwareArchitectureComponent {
  static route = 'origin-of-software-architecture';
}
