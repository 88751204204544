import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

export interface SEOConfig {
  title: string;
  canonicalUrl: string;
}
@Injectable({
  providedIn: 'root',
})
export class SeoService {
  static prefixURL = 'https://scifamek.com';
  constructor(private meta: Meta, private title: Title) {}

  setUp(config: SEOConfig) {
    this.title.setTitle(`Scifamek - ${config.title}`);

    const canonical = `${SeoService.prefixURL}/${config.canonicalUrl}`;
    let prevTag: HTMLMetaElement | null = document.querySelector(
      `meta[name="canonical"]`
    );

    console.log(prevTag);
    if (prevTag) {
      prevTag.setAttribute('name', 'canonical');
      prevTag.setAttribute('content', canonical);
    } else {
      this.meta.addTag({
        name: 'canonical',
        content: canonical,
      });
    }
    this.addCanonicalLink(canonical);
  }

  addCanonicalLink(canonical: string) {
    let link: HTMLLinkElement | null = document.querySelector(
      'link[rel="canonical"]'
    );

    if (link) {
      link.setAttribute('href', canonical);
    } else {
      const link: HTMLLinkElement = document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', canonical);
      document.head.appendChild(link);
    }
  }
}
