export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyAaHruPP0bU1wwF_lVjx4x-_evo0ECli0Y',
    authDomain: 'main-web-site-ffc6e.firebaseapp.com',
    projectId: 'main-web-site-ffc6e',
    storageBucket: 'main-web-site-ffc6e.appspot.com',
    messagingSenderId: '101245782287',
    appId: '1:101245782287:web:ece85b0d435e216cd9167a',
    measurementId: 'G-0TC32QHTY2',
  },
};
