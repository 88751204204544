<section
  class="d-flex flex-column-reverse flex-lg-row row m-0 col-12 col-sm-9 col-md-7 col-lg-10 col-xxl-9 m-auto py-3 py-md-1"
>
  <div
    class="m-auto col-10 col-sm-8 col-lg-4 d-flex flex-column align-items-center justify-content-center"
  >
    <div
      *ngFor="let item of contacts"
      class="ccard d-flex align-items-center my-1 col-12"
    >
      <figure (click)="item.command()" class="col-4 d-flex m-0 p-1 border-end">
        <img class="w-75 m-auto" [src]="item.path" [attr.alt]="item.title.join(' ')" srcset="" />
      </figure>
      <div class="col-8 px-3">
        <p class="p-0 m-0" *ngFor="let item of item.title">{{ item }}</p>
      </div>
    </div>
  </div>

  <form
    class="m-auto col-11 col-sm-12 col-lg-8  d-flex flex-wrap"
    [formGroup]="formGroup"
  >
    <div class="d-flex flex-column my-2 gap-2 col-12 col-md-6 px-2">
      <label htmlFor="firstName">Primer apellido</label>
      <input
        pInputText
        id="firstName"
        aria-describedby="firstName-help"
        formControlName="firstName"
      />
    </div>
    <div class="d-flex flex-column my-2 gap-2 col-12 col-md-6 px-2">
      <label htmlFor="secondName">Segundo nombre</label>
      <input
        pInputText
        id="secondName"
        aria-describedby="secondName-help"
        formControlName="secondName"
      />
    </div>

    <div class="d-flex flex-column my-2 gap-2 col-12 col-md-6 px-2">
      <label htmlFor="firstSurname">Primer apellido</label>
      <input
        pInputText
        id="firstSurname"
        aria-describedby="firstSurname-help"
        formControlName="firstSurname"
      />
    </div>

    <div class="d-flex flex-column my-2 gap-2 col-12 col-md-6 px-2">
      <label htmlFor="secondSurname">Segundo apellido</label>
      <input
        pInputText
        id="secondSurname"
        aria-describedby="secondSurname-help"
        formControlName="secondSurname"
      />
    </div>

    <div class="d-flex flex-column my-2 gap-2 col-12 col-md-6 px-2">
      <label htmlFor="email">Correo electrónico</label>
      <input
        pInputText
        id="email"
        aria-describedby="email-help"
        formControlName="email"
      />
    </div>
    <div class="d-flex flex-column my-2 gap-2 col-12 col-md-6 px-2">
      <label htmlFor="phone">Celular</label>
      <input
        pInputText
        id="phone"
        aria-describedby="phone-help"
        formControlName="phone"
      />
    </div>
    <div class="d-flex flex-column my-2 gap-2 col-12 px-2">
      <label htmlFor="message">Mensaje</label>

      <textarea
        id="message"
        rows="4"
        cols="30"
        pInputTextarea
        formControlName="message"
      ></textarea>
    </div>

    <div class="col-12 py-3 d-flex">
      <p-button
        [disabled]="sending"
        (click)="send()"
        styleClass="p-button-outlined p-button-rounded m-auto p-button-sm"
        class="m-auto"
        >Enviar</p-button
      >
    </div>
  </form>
</section>
<p-toast></p-toast>
