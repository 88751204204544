import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AnaliticsService } from 'src/app/services/analitics.service';
import { SEOConfig, SeoService } from 'src/app/services/seo.service';
import { SERVICE_MODULE_ROUTE } from '../config';

@Component({
  selector: 'app-technological-talents',
  templateUrl: './technological-talents.component.html',
  styleUrls: ['./technological-talents.component.scss'],
})
export class TechnologicalTalentsComponent {
  static route = 'prestacion-de-talentos-a-empresas';
  static config: SEOConfig = {
    title: 'Prestación de talentos',
    canonicalUrl: [
      SERVICE_MODULE_ROUTE,
      TechnologicalTalentsComponent.route,
    ].join('/'),
  };
  items: MenuItem[];
  data: any[];
  currentIndex: number = 0;

  constructor(
    private analiticsService: AnaliticsService,
    private seo: SeoService
  ) {
    this.data = [
      {
        description:
          'Reunión con la empresa cliente para comprender sus necesidades tecnológicas y definir el perfil ideal del talento. Esto incluye las habilidades técnicas, la experiencia, las metodologías de trabajo preferidas.',
        image: 'assets/images/header.jpg',
        title: 'Definición del perfil',
      },
      {
        description:
          'Realiza una preselección de los currículums recibidos y realiza entrevistas preliminares para evaluar la compatibilidad del candidato con el perfil definido.',
        image: 'assets/images/reuion_inicial.jpg',
        title: 'Filtrado de candidatos',
      },
      {
        description:
          'Reunión con la empresa cliente para comprender sus necesidades tecnológicas y definir el perfil ideal del talento. Esto incluye las habilidades técnicas, la experiencia, las metodologías de trabajo preferidas.',
        image: 'assets/images/prueba_tecnica.jpg',
        title: 'Evaluación técnica',
      },
      {
        description:
          'pruebas técnicas o proyectos prácticos para evaluar sus competencias y confirmar que pueden cumplir con las expectativas tecnológicas del cliente.',
        image: 'assets/images/onboarding.jpg',
        title: 'Onboarding',
      },
    ];

    this.items = this.data.map((x) => ({
      label: '',
    }));
  }

  onStepChange(event: number): void {
    this.currentIndex = event;
  }

  ngOnInit(): void {
    this.analiticsService.log('technological_talents_service_visit');
    this.seo.setUp(TechnologicalTalentsComponent.config);
  }
}
