import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { ServicesRoutingModule } from 'src/app/views/services/services.routing.module';
import { LandingServiceComponent } from '../services/landing-service/landing-service.component';
import { TechnologicalTalentsComponent } from '../services/technological-talents/technological-talents.component';
interface HowWeDoIt {
  icon: AnimationOptions;
  title: string;
  steps: string[];
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(private router: Router) {
    this.howWeDoIt = [
      {
        title: 'Análisis de Requisitos y Planificación del Proyecto',
        icon: {
          path: '../assets/images/how-do-we-do-it/rocket.json',
        },
        steps: [
          'Definir los requisitos funcionales y no funcionales, creando documentación detallada.',
          'Establecer los límites del proyecto y sus objetivos.',
          'Crear un cronograma detallado que incluya hitos, entregables y plazos.',
          'Identificar y asignar los recursos humanos, tecnológicos y financieros necesarios.',
          'Identificar posibles riesgos y desarrollar estrategias para mitigarlos.',
        ],
      },
      {
        title: 'Diseño del Software',
        icon: {
          path: '../assets/images/how-do-we-do-it/light-bulb.json',
        },
        steps: [
          'Diseñar la arquitectura del sistema, incluyendo la selección de tecnologías, frameworks y patrones de diseño.',
          'Definir la estructura de la base de datos y las relaciones entre las entidades.',
          'Crear prototipos o mockups para visualizar la interfaz de usuario y la experiencia del usuario (UI/UX).',
          'Documentar las especificaciones técnicas detalladas que guiarán la implementación.',
        ],
      },
      {
        title: 'Desarrollo',
        icon: { path: '../assets/images/how-do-we-do-it/satellite.json' },
        steps: [
          'Escribir el código fuente del software según las especificaciones y la arquitectura definida.',
          'Usar sistemas de control de versiones (como Git) para gestionar el código.',
          'Implementar prácticas de integración continua para asegurar que el código desarrollado por diferentes equipos funcione bien conjuntamente.',
          'Realizar revisiones de código para mantener la calidad y consistencia del software.',
        ],
      },
      {
        title: 'Pruebas',
        icon: {
          path: '../assets/images/how-do-we-do-it/hand.json',
        },
        steps: [
          'Verificar que cada unidad del código funcione correctamente.',
          'Asegurar que los diferentes módulos del software funcionen juntos sin problemas.',
          'Realizar pruebas con usuarios finales para detectar problemas de usabilidad y asegurarse de que el software cumpla con las expectativas del cliente.',
          'Identificar y solucionar posibles vulnerabilidades.',
        ],
      },
      {
        title: 'Implementación',
        icon: {
          path: '../assets/images/how-do-we-do-it/travel-destination.json',
        },
        steps: [
          'Lanzar el software en el entorno de producción.',
          'Si es necesario, migrar los datos existentes al nuevo sistema.',
          'Capacitar a los usuarios finales y al equipo de soporte sobre el uso y mantenimiento del software.',
          'Monitorizar las plataformas desplegadas',
        ],
      },
    ];

    setTimeout(() => {
      this.handleDevelopmentSteps(this.howWeDoIt[0]);
    }, 200);
  }

  lottieOptions = {
    path: 'assets/rocket.json', // Ruta de tu animación
  };

  howWeDoIt: HowWeDoIt[];

  currentHowWeDoIt: HowWeDoIt | undefined;
  isAnimated: boolean = false;

  ngOnInit() {

  }

  onClickTechnologicalTalents() {
    console.log('entro ... ');
    this.router.navigate([
      ServicesRoutingModule.route,
      TechnologicalTalentsComponent.route,
    ]);
  }

  handleDevelopmentSteps(hwdi: HowWeDoIt) {
    this.isAnimated = false;
    this.currentHowWeDoIt = hwdi;

    setTimeout(() => {
      this.isAnimated = true;
    }, 200);
  }
}
