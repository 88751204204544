import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { addDoc, collection, Firestore } from 'firebase/firestore';
import { MessageService } from 'primeng/api';
import { FIRESTORE_TOKEN } from 'src/app/app.module';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent {
  static route = 'contact-us';

  contacts = [
    {
    
      title: ['+57 3124825467', '+57 3104331715'],
      path: 'assets/views/contact-us/whatsapp.png',
      command: () => {
        const url = 'https://wa.link/xqchnz';
        window.open(url, '_blank');
      },
    },
    {
      title: ['scifamek@gmail.com'],
      path: 'assets/views/contact-us/gmail.webp',
      command: () => {
        const url =
          'mailto:scifamek@gmail.com?cc=&bcc=&subject=Quiero%20saber%20m%C3%A1s%20de%20uds&body=';
        window.open(url, '_blank');
      },
    },
    {
      title: ['scifamek'],
      path: 'assets/views/contact-us/instagram.png',
      command: () => {
        const url = 'https://www.instagram.com/scifamek/';
        window.open(url, '_blank');
      },
    },
  ];

  formGroup: FormGroup;
  sending: boolean = false;

  constructor(
    private fb: FormBuilder,
    @Inject(FIRESTORE_TOKEN) private firestore: Firestore,
    private messageService: MessageService
  ) {
    this.formGroup = this.fb.group({
      firstName: this.fb.control(''),
      secondName: this.fb.control(''),
      firstSurname: this.fb.control(''),
      secondSurname: this.fb.control(''),
      message: this.fb.control(''),
      email: this.fb.control(''),
      phone: this.fb.control(''),
    });
  }

  send() {
    this.sending = true;
    const value = this.formGroup.value;
    const colRef = collection(this.firestore, 'contact-requests');
    addDoc(colRef, value).then(() => {
      this.sending = false;
      this.messageService.add({
        severity: 'success',
        summary: 'Enviado',
        detail: 'Mensaje enviado. En breve nos contactaremos contigo.',
      });
    });
  }
}
