<header class="p-2 pt-2 pt-sm-2 mt-2 mt-sm-2 mb-xl-2">
  <h1 class="my-5 text-center">Plataformas qué automatizan tu empresa</h1>
  <p class="text-center fs-4 my-3 my-md-2 px-2 px-sm-4 px-md-5">
    Automatizar tus
    <strong class="txt-primary"> procesos </strong>
    es indispensable para lograr la mayor

    <strong class="txt-primary"> eficiencia.</strong>
  </p>
</header>

<section class="d-flex flex-column flex-md-row">
  <div class="col-12 col-md-6 col-lg-7 col-xl-5">
    <figure class="m-auto col-5 col-sm-3 col-md-5 col-lg-4 col-xl-3 mb-3">
      <img
        class="w-100"
        src="assets/views/products-saas/facires.png"
        alt="Facires, limpieza, domicilios, software"
        srcset=""
      />
    </figure>
    <div class="px-3 py-sm-3 text-center m-auto col-12 col-xl-8">
      <p>
        Agenda y gestiona los servicios pendientes a prestar, de manera fácil,
        rápida y automática.
      </p>
      <p>
        Administra los servicios prestados permitiendo ahorrar tiempo, costos y
        errores.
      </p>
    </div>
  </div>
  <section class="service-option-container col-12 col-md-6 col-lg-5 col-xl-7">
    <div class="service-option">
      <figure>
        <img
          src="assets/views/products-saas/cleaners.jpg"
          alt="limpieza, aseadores, facires"
          srcset=""
          class="animate__animated animate__bounceIn"
        />
      </figure>
      <div>
        <h3>Limpieza</h3>
        <p>Hogares, comercios, industrias, eventos.</p>
      </div>
    </div>
    <div class="service-option">
      <figure>
        <img
          src="assets/views/products-saas/garda-pest-control.jpg"
          alt="control de plagas, domicilios"
          srcset=""
          class="animate__animated animate__bounceIn"
        />
      </figure>
      <div>
        <h3>Control de plagas</h3>
        <p>Domicilios, comercios, industrias, agrícola.</p>
      </div>
    </div>
    <div class="service-option">
      <figure>
        <img
          src="assets/views/products-saas/healthy.jpg"
          alt="salud al hogar, salud, medicina, domicilios"
          srcset=""
          class="animate__animated animate__bounceIn"
        />
      </figure>
      <div>
        <h3>Salud al hogar</h3>
        <p>Medicina, enfermería, cuidado personal, psicología, telemedicina.</p>
      </div>
    </div>
    <div class="service-option">
      <figure>
        <img
          src="assets/views/products-saas/maintainer.jpg"
          alt="mantenimiento, docimilios"
          srcset=""
          class="animate__animated animate__bounceIn"
        />
      </figure>
      <div>
        <h3>Mantenimiento</h3>
        <p>Plomería, electricidad, gas, jardinería, paisajismo, pintura.</p>
      </div>
    </div>
  </section>
</section>

<section class="my-3">
  <p-button
    (click)="command()"
    styleClass="p-button-rounded p-button-outlined"
    label="Vistiar Facires"
  ></p-button>
</section>

<section class="col-12 col-xl-8 mt-xl-4">
  <div class="pb-3 px-3 mt-3 px-sm-5 text-center">
    <p>
      Si estás buscando una solución eficiente en la nube para optimizar y
      automatizar tus procesos empresariales, nuestro servicio SaaS puede ser lo
      que necesitas. Ofrecemos una plataforma diseñada para facilitar la gestión
      de diversas operaciones en tu empresa, con una integración rápida y sin
      complicaciones.
    </p>
  </div>
</section>

<p-divider></p-divider>

<section class="my-5"></section>
