import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NosqlSqlComparativeComponent } from '../topics/technical/nosql-sql-comparative/nosql-sql-comparative.component';
import { OriginOfSoftwareArchitectureComponent } from '../topics/technical/origin-of-software-architecture/origin-of-software-architecture.component';

@Component({
  selector: 'app-blog-index',
  templateUrl: './blog-index.component.html',
  styleUrls: ['./blog-index.component.scss'],
})
export class BlogIndexComponent {
  menu = [
    {
      display: 'Comparación entre bases de datos relacionales y no relacionales',
      route: NosqlSqlComparativeComponent.route,
    },
    {
      display: 'Origen de la arquitectura de software',
      route: OriginOfSoftwareArchitectureComponent.route,
    },
  ];

  constructor(private router: Router, private ar: ActivatedRoute) {}

  navigate(item: { display: string; route: string }) {
    this.router.navigate(['wip-' + item.route], {
      relativeTo: this.ar,
    });
  }
}
