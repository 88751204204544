import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlogIndexComponent } from './blog-index/blog-index.component';
import { NosqlSqlComparativeComponent } from './topics/technical/nosql-sql-comparative/nosql-sql-comparative.component';
import { OriginOfSoftwareArchitectureComponent } from './topics/technical/origin-of-software-architecture/origin-of-software-architecture.component';

const routes: Routes = [
  {
    path: ``,
    component: BlogIndexComponent,
  },
  {
    path: `wip-${NosqlSqlComparativeComponent.route}`,
    component: NosqlSqlComparativeComponent,
  },
  {
    path: `wip-${OriginOfSoftwareArchitectureComponent.route}`,
    component: OriginOfSoftwareArchitectureComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BlogRoutingModule {
  static route = 'blog';
}
