<div *ngIf="service" class="card">
  <div
    [style]="{
      'background-size': 'cover',
      cursor: service.path ? 'pointer' : 'initial',
      background:
        'transparent linear-gradient(' +
        service.fromColor +
        ', ' +
        service.toColor +
        ')'
    }"
    class="card__header"
  >
    
    <h1 class="card__title">{{ service.name }}</h1>
    <span class="card__subtitle">{{ service.description }}</span>
  </div>

  <div class="card__body">
    <img
      [src]="service.image"
      [attr.alt]="service.name + ' Scifamek'"
      class="card__image"
    />
    <span
      role="button"
      *ngIf="service.path"
      (click)="navigate(service)"
      [style]="{
        border: '1px solid ' + service.toColor,
        color: service.toColor,
        'box-shadow': '1px 0 2px 1px ' + service.toColor + ''
      }"
      class="card__wish-list"
    >
      Detalles
    </span>
  </div>
</div>
