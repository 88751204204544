<header class="p-2 pt-2 pt-sm-2 mt-2 mt-sm-2">
    <h1 class="my-5 text-center">Lleva tu Empresa al Siguiente Nivel</h1>
    <h2 class="text-center my-3 my-md-2 px-2 px-sm-4 px-md-5">
        Talento
        <strong class="txt-primary"> Especializado </strong>
        para tus Proyectos

        <strong class="txt-primary"> tecnológicos. </strong>
    </h2>
</header>
<section>

    <div class="pb-3 px-2 px-sm-5 text-center">
        <p>
            Conectamos a las empresas con desarrolladores de software, arquitectos tecnológicos y especialistas en
            metodologías ágiles y soluciones en la nube. Ya sea que necesites construir software a medida,
            diseñar arquitecturas escalables o migrar a la nube, nuestro equipo de expertos está listo para transformar
            tus proyectos.
        </p>
    </div>
</section>


<p-divider></p-divider>

<section class="d-flex col-xl-10 flex-wrap mx-auto  justify-content-evenly">
    <div class="col-11 col-lg-5 col-md-8 col-sm-8 mx-auto  ">
        <h2 class="text-center">Nuestros Servicios Incluyen:</h2>
        <ul>
            <li class="py-2"><strong>Desarrolladores de software</strong> con experiencia en las tecnologías más
                avanzadas.</li>
            <li class="py-2"><strong>Arquitectos de software</strong> para diseñar infraestructuras robustas y
                escalables.</li>
            <li class="py-2"><strong>Expertos en metodologías ágiles</strong> que optimizan tus procesos de desarrollo.
            </li>
            <li class="py-2"><strong>Especialistas en la nube</strong> que te guían en la adopción y gestión de
                soluciones cloud.</li>
        </ul>
        <p class="text-center"><strong class="txt-primary">Acelera la innovación y alcanza tus metas con el talento adecuado.</strong></p>
    </div>

    <div class="col-11 col-lg-6">
        <div class="container align-items-center  d-flex flex-column">
            <h5>{{data[currentIndex].title}}</h5>
            <div class="w-100 my-3">
                <p-steps [model]="items" [activeIndex]="currentIndex" [readonly]="false"
                (activeIndexChange)="onStepChange($event)"></p-steps>
            </div>
            <figure class="col-xl-6 col-lg-4 col-md-5 col-sm-12">
                <img [src]="data[currentIndex].image" alt="image" class="w-100 rounded" />
            </figure>
            <p class=" text-center">{{data[currentIndex].description}}</p>
            
            
        </div>

    </div>
</section>

<p-divider></p-divider>