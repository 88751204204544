import { InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'primeng/carousel';
import { DockModule } from 'primeng/dock';
import { PanelModule } from 'primeng/panel';
import { TagModule } from 'primeng/tag';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MegaMenuModule } from 'primeng/megamenu';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ImageModule } from 'primeng/image';
import { RadioButtonModule } from 'primeng/radiobutton';

//import { AngularFireModule } from '@angular/fire/compat';
//import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { LottieModule } from 'ngx-lottie';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { MenubarModule } from 'primeng/menubar';
import { environment } from 'src/environments/environment';
import { ComponentsModule } from './components/components.module';
import { playerFactory } from './views/player-factory.lottie';
import { ViewsModule } from './views/views.module';

export const FIREBASE_TOKEN = new InjectionToken<string>('firebase');
export const FIRESTORE_TOKEN = new InjectionToken<string>('firestore');
export const ANALITICS_TOKEN = new InjectionToken<string>('analitics');

const app = initializeApp(environment.firebase);
console.log(app);


@NgModule({
  declarations: [AppComponent],
  imports: [
    ComponentsModule,
    LottieModule.forRoot({ player: playerFactory }),
    //AngularFireModule.initializeApp(environment.firebase),

    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    ButtonModule,
    TagModule,
    PanelModule,
    CardModule,
    BrowserAnimationsModule,
    DockModule,
    RadioButtonModule,
    CommonModule,
    FormsModule,
    MegaMenuModule,
    ImageModule,
    ViewsModule,
    MenubarModule,
  ],
  providers: [
    {
      provide: FIREBASE_TOKEN,
      useValue: app,
    },
    {
      provide: FIRESTORE_TOKEN,
      useValue: getFirestore(app),
    },
    {
      provide: ANALITICS_TOKEN,
      useValue: getAnalytics(app),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
