import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'primeng/carousel';
import { DockModule } from 'primeng/dock';
import { PanelModule } from 'primeng/panel';
import { TagModule } from 'primeng/tag';

import { MegaMenuModule } from 'primeng/megamenu';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageModule } from 'primeng/image';
import { RadioButtonModule } from 'primeng/radiobutton';

import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { SkeletonModule } from 'primeng/skeleton';
import { ComponentsModule } from '../components/components.module';
import { HomeComponent } from './home/home.component';

import { LottieModule } from 'ngx-lottie';
import { MessageService } from 'primeng/api';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ToastModule } from 'primeng/toast';
import { AnaliticsService } from '../services/analitics.service';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { playerFactory } from './player-factory.lottie';

@NgModule({
  declarations: [HomeComponent, ContactUsComponent],
  imports: [
    ComponentsModule,
    LottieModule.forRoot({ player: playerFactory }),

    DividerModule,
    BrowserModule,
    CarouselModule,
    ToastModule,
    ButtonModule,
    TagModule,
    PanelModule,
    CardModule,
    BrowserAnimationsModule,
    InputTextareaModule,
    DockModule,
    RadioButtonModule,
    CommonModule,
    FormsModule,
    MegaMenuModule,
    ImageModule,
    InputTextModule,
    SkeletonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [MessageService, AnaliticsService],
})
export class ViewsModule {}
