import { Component } from '@angular/core';

@Component({
  selector: 'app-nosql-sql-comparative',
  templateUrl: './nosql-sql-comparative.component.html',
  styleUrls: ['./nosql-sql-comparative.component.scss']
})
export class NosqlSqlComparativeComponent {
static route = 'nosql-sql-comparative';
}
