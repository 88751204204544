import { Component } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
//import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(private adf: PrimeNGConfig) {
    this.adf.zIndex = {
      menu: 1000,     // overlay menus

    }
  }
}
