import { Component, OnInit } from '@angular/core';
import { AnaliticsService } from 'src/app/services/analitics.service';
import { SEOConfig, SeoService } from 'src/app/services/seo.service';
import { SERVICE_MODULE_ROUTE } from '../config';

type Languaje = 'nodejs' | 'java';
type State = 'stable' | 'beta' | 'alfa';
export interface ComponentService {
  name: string;
  description: string;

  languages: { language: Languaje; items?: string[]; version: string }[];
  price?: string;
  state: State;
}
@Component({
  selector: 'app-components-service',
  templateUrl: './components-service.component.html',
  styleUrls: ['./components-service.component.scss'],
})
export class ComponentsServiceComponent implements OnInit {
  static route = 'librerias-de-software';

  static config: SEOConfig = {
    title: 'Librerías',
    canonicalUrl: [SERVICE_MODULE_ROUTE, ComponentsServiceComponent.route].join(
      '/'
    ),
  };

  components: ComponentService[];
  filteredComponents!: ComponentService[];

  selectedState: {
    value: State;
    display: string;
  };
  availableStates: {
    value: State;
    display: string;
  }[];
  iconsTable: any;
  statesTable: { [i in State]: string };

  constructor(
    private analiticsService: AnaliticsService,
    private seo: SeoService
  ) {
    this.iconsTable = {
      nodejs: 'assets/views/components-service/node-js.png',
      java: 'assets/views/components-service/java.png',
    };

    this.statesTable = {
      stable: 'Estable',
      beta: 'Beta',
      alfa: 'Alfa',
    };
    this.availableStates = Object.entries(this.statesTable).map((state) => ({
      value: state[0] as State,
      display: state[1],
    }));
    this.selectedState = this.availableStates[0];

    this.components = [
      {
        name: 'Iraca',
        description:
          'Este es un marco mínimo para crear un servidor orientado a una arquitectura limpia.',

        languages: [
          {
            language: 'nodejs',
            version: '3.0.0',
            items: ['Integración con Firebase - Firestore'],
          },
          {
            language: 'java',
            version: 'Próximamente',
          },
        ],
        state: 'beta',
      },

      // Juliet
      {
        name: 'Guatavita',
        description: 'Librería para usar GeoHash con múltiples bases.',
        languages: [
          {
            language: 'nodejs',
            version: '1.0.1',
            items: [
              'Creación de diversas bases',
              'Base 32, 64 y 128 incorporadas por defecto',
              'Separador del alfabeto configurable',
              'Elección de la mejor base de acuerdo al rango de búsqueda',
            ],
          },
        ],

        state: 'beta',
      },
      {
        name: 'Tairona',
        description:
          'Librería para la transformación y validación de imágenes.',

        languages: [
          {
            language: 'nodejs',
            version: '1.0.1',
            items: [
              'Permite efectuar validaciones sobre una imagen a través de un interfaz declarativa',
              'Permite realizar transformaciones de manera declarativa a un imagen',
              'Permite validar si una imagen es PNG',
              'Permite obtener la información de una imagen dado su Base64',
            ],
          },
        ],
        state: 'beta',
      },
      {
        name: 'Quimbaya',
        description: 'Sistema de diseño agnóstico WEB',
        languages: [
          {
            language: 'nodejs',
            version: 'Próximamente',
          },
        ],
        state: 'alfa',
      },

      {
        name: 'Logger',
        description: 'Librería para usar GeoHash con múltiples bases.',
        languages: [
          {
            language: 'nodejs',
            version: '1.1.0',
            items: [
              'Niveles configurables, por defecto debug, info, error, warn',
              'Separador configurable, por defecto :',
              'Método genérico para el registro de log',
              'Método específico por cada nivel del logger',
              'Permite combinar mensajes en un grupo',
              'Escribe los logs en un archivo local. Sobreescribe el mismo si ya existe',
            ],
          },
        ],

        state: 'stable',
      },
      {
        name: 'Standart Validators',
        description:
          'Herramienta para la validación de reglas de código en tus respositorios. Puedes parametrizar la que tu quieras o usar una de las que nosotros hemos creado.',
        languages: [
          {
            language: 'nodejs',
            version: 'Próximamente',
          },
        ],
        state: 'beta',
      },
      {
        name: 'Teyuna',
        description:
          'Framework para la creación de flujos de trabajo con las operaciones básicas y avanzadas sobre las entidades de tu negocio.',
        languages: [
          {
            language: 'nodejs',
            version: 'Próximamente',
            items: [
              'Definición de modelos de manera declarativa',
              'Actualización de la entidad de manera automática',
              'Soporte de paginación',
              'Soporte para tags dentro de la vista de listado',
              'Configuración de acciones por registro',
              'Configuración de acciones generales',
              'Visualización declarativa de formularios',
              'Componentes reactivos',
            ],
          },
        ],

        price: '1200000',
        state: 'stable',
      },
    ];
    this.filter();
  }

  filter() {
    this.filteredComponents = this.components.filter(
      (component) => component.state == this.selectedState.value
    );
  }
  ngOnInit(): void {
    this.analiticsService.log('components_service_visit');
    this.seo.setUp(ComponentsServiceComponent.config);
  }
}
