import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlogRoutingModule } from './views/blog/blog-routing.module';
import { ContactUsComponent } from './views/contact-us/contact-us.component';
import { HomeComponent } from './views/home/home.component';
import { ServicesRoutingModule } from './views/services/services.routing.module';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: ContactUsComponent.route,
    component: ContactUsComponent,
  },
  {
    path: ServicesRoutingModule.route,
    loadChildren: () =>
      import('./views/services/services.module').then((x) => x.ServicesModule),
  },
  {
    path: BlogRoutingModule.route,
    loadChildren: () =>
      import('./views/blog/blog.module').then((x) => x.BlogModule),
  },
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
