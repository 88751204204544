import { Component } from '@angular/core';
import { AnaliticsService } from 'src/app/services/analitics.service';
import { SEOConfig, SeoService } from 'src/app/services/seo.service';
import { SERVICE_MODULE_ROUTE } from '../config';

export interface Product {
  name: string;
  logo: string;
}

@Component({
  selector: 'app-products-saas',
  templateUrl: './products-saas.component.html',
  styleUrls: ['./products-saas.component.scss'],
})
export class ProductsSaasComponent {
  static route = 'software-como-servicios';

  static config: SEOConfig = {
    title: 'Softwares como servicios',
    canonicalUrl: [SERVICE_MODULE_ROUTE, ProductsSaasComponent.route].join('/'),
  };

  products: Product[];
  constructor(
    private analiticsService: AnaliticsService,

    private seo: SeoService
  ) {
    this.products = [];
  }

  command() {
    const url = 'https://facires.com/';
    window.open(url, '_blank');
  }

  ngOnInit(): void {
    this.analiticsService.log('products_saas_service_visit', {
      product: 'facires',
    });
    this.seo.setUp(ProductsSaasComponent.config);
  }
}
