import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ComponentsServiceComponent } from './components-service/components-service.component';
import { SERVICE_MODULE_ROUTE } from './config';
import { LandingServiceComponent } from './landing-service/landing-service.component';
import { ProductsSaasComponent } from './products-saas/products-saas.component';
import { TechnologicalTalentsComponent } from './technological-talents/technological-talents.component';

const routes: Routes = [
  {
    path: LandingServiceComponent.route,
    component: LandingServiceComponent,
  },
  {
    path: TechnologicalTalentsComponent.route,
    component: TechnologicalTalentsComponent,
  },
  {
    path: ComponentsServiceComponent.route,
    component: ComponentsServiceComponent,
  },
  {
    path: ProductsSaasComponent.route,
    component: ProductsSaasComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ServicesRoutingModule {
  static route = SERVICE_MODULE_ROUTE;
}
