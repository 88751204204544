import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { ContactUsComponent } from 'src/app/views/contact-us/contact-us.component';
import { ComponentsServiceComponent } from 'src/app/views/services/components-service/components-service.component';
import { LandingServiceComponent } from 'src/app/views/services/landing-service/landing-service.component';
import { ProductsSaasComponent } from 'src/app/views/services/products-saas/products-saas.component';
import { ServicesRoutingModule } from 'src/app/views/services/services.routing.module';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  items: MenuItem[];
  constructor(private router: Router) {
    this.items = [
      {
        label: 'Servicios',
        icon: 'pi pi-box',
        items: [
          {
            label: 'Página Web',
            command: () => {
              this.router.navigate([
                ServicesRoutingModule.route,
                LandingServiceComponent.route,
              ]);
            },
          },
          {
            label: 'Librerías',
            command: () => {
              this.router.navigate([
                ServicesRoutingModule.route,
                ComponentsServiceComponent.route,
              ]);
            },
          },
          {
            label: 'Productos SaaS',
            command: () => {
              this.router.navigate([
                ServicesRoutingModule.route,
                ProductsSaasComponent.route,
              ]);
            },
          },
        ],
      },
      // {
      //   label: 'Enlaces utilies',
      //   icon: 'pi pi-mobile',
      //   items: [
      //     {
      //       label: 'En que somos buenos?',
      //       command: () => this.handleMegaMenu('seccion3'),
      //     },
      //     {
      //       label: 'Como lo hacemos?',
      //       command: () => this.handleMegaMenu('seccion4'),
      //     },
      //   ],
      // },
      {
        label: 'Contáctanos',
        icon: 'pi pi-envelope',
        command: () => {
          this.router.navigate([
            ContactUsComponent.route,
          ]);
        },
      },
    ];
    // this.menuPrincipalItems = [
    //   {
    //     label: 'Servicios',
    //     icon: 'pi pi-box',
    //   },
    //   {
    //     label: 'Contacto',
    //     icon: 'pi pi-mobile',
    //   },
    //   {
    //     label: 'Productos',
    //     icon: 'pi pi-mobile',
    //     items: [
    //       {
    //         label: 'Facires',
    //         command: () => this.handleMegaMenu('seccion5'),
    //       },
    //     ],
    //   },
    // ];
  }

  handleMegaMenu(seccionId: string) {
    const section = document.getElementById(seccionId);

    if (seccionId === 'seccion5') {
      const url = 'https://facires.com/';
      window.open(url, '_blank');
    } else if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }
  ngOnInit(): void {}
}
