<header>
  <h1>Origen de la arquitectura de software</h1>
</header>
<p>
  ¿A cuántos de los que leen este documento se les ha ocurrido pensar en el
  origen de algo para entender el mismo? Si respondió en su mente que ud no,
  entonces déjeme decirle que no tengo ni idea cómo llegó a esta página.
</p>
<p>
  Ahora bien, entrando en materia, es muy común querer entender un tema
  cualquiera, ya sea para una exposición en la U, para la presentación frente al
  equipo de trabajo en una empresa o para ampliar el conocimiento sobre dicho
  tema, pero rara vez veo querer entender el origen de un concepto. Mi interés
  con este escrito es ayudar a la comprensión de la arquitectura de software
  desde un punto de vista evolutivo.
</p>
<p>
  Para empezar, quiero hacer un breve experimento mental. Responde esta
  pregunta: ¿Qué es la alquimia? Podrías responder algo como "una pseudociencia
  basada en ficción" o "la precursora de la química moderna"
</p>
<p>
  Ya con el experimento mental anterior, podemos empezar a remontarnos unas
  cuantas décadas al pasado. Empecemos por los años 50's. Acá aparecen los
  primeros computadores digitales comerciales como el IBM 704 con Fortran como
  lenguaje de alto nivel. Para esta época ya no era necesario escribir las
  instrucciones en tarjetas perforadas, en binario (siguiendo el set de
  instrucciones de la computadora en específico) o en ensamblador de la
  arquitectura en la que se estuviera trabajando; acá podíamos hablar de un
  código fuente, escrito en un lenguaje de alto nivel, que usaba un compilador
  para emitir el código objetivo de ensamblador de la arquitectura deseada.
  Pero, ¿qué tiene que ver esto con arquitectura? Piénselo de la siguiente manera. Imagine por un momento los programas que se desarrollaban en ese momento, piense también en la variedad de equipos de cómputo que salían al mercado, todos ellos diferentes. Cree ud que algo de la IBM 704 se podría
</p>

<div>
  Primeras abstracciones Las operaciones de suma y resta The substitution of
  simple symbols for machine operation codes, machine addresses yet to be
  defined, and sequences of instructions was perhaps the earliest form of
  abstraction in software. Garlan & Shaw (1994). An Introduction to Software
  Architecture, School of Computer Science Abstract Data Types Qué es
  arquitectura
</div>
