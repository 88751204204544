import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

export interface Service {
  name: string;
  description: string;
  fromColor: string;
  toColor: string;
  image: string;
  path?: string;
}

@Component({
  selector: 'app-service-card',
  templateUrl: './service-card.component.html',
  styleUrls: ['./service-card.component.scss'],
})
export class ServiceCardComponent implements OnInit {
  @Input() service?: Service;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  navigate(_t4: {
    name: string;
    description: string;
    fromColor: string;
    toColor: string;
    image: string;
    path?: string;
  }) {
    if (_t4.path) {
      this.router.navigateByUrl(_t4.path);
    }
  }
}
