<div
  class="col-12 col-xxl-11 m-auto d-flex flex-wrap justify-content-center"
>
  <div
    class="col-11 col-sm-10 col-md-6 col-lg-5 col-xl-4 col-xxl-4 py-2 px-2 px-sm-2 px-lg-3"
    *ngFor="let service of services"
  >
    <app-service-card [service]="service"> </app-service-card>
  </div>

  <!-- <div  >
    <div
      class="card "
      [style]="{
        'background-image': 'url(' + service.image + ')',
        'background-size': 'cover',
        'cursor':  service.path ? 'pointer' : 'initial',
        'background-position': 'center2024-09-23 12:38:16'
      }"
    >
      <div class="content">
        <h3 class="title">{{ service.name }}</h3>
        <p class="copy px-1">
          {{ service.description }}
        </p>

      </div>
    </div>
  </div> -->
</div>
