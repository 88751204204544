<header class="p-2 pt-2 pt-sm-2 mt-2 mt-sm-2">
  <h1 class="my-5 text-center py-3">
    Adquiere tu propio sitio web empresarial
  </h1>
</header>

<section class="d-flex flex-wrap align-items-center justify-content-center">
  <div
    class="col-12 col-sm-10 col-md-5 col-lg-5 col-xl-6 col-xxl-5 d-flex justify-content-center"
  >
    <figure class="col-9 col-sm-8 col-md-10 col-lg-10 col-xl-10">
      <img
        class="w-100"
        src="assets/views/landing-service/web.webp"
        alt="desarrollo de software"
      />
    </figure>
  </div>
  <div
    class="col-12 col-sm-11 col-md-7 col-lg-7 col-xl-6 col-xxl-7 pb-3 px-2 px-sm-3 text-center"
  >
    <h2 class="text-center my-3 my-md-2 px-2 px-sm-4 px-md-5">
      Todos tus
      <strong class="txt-primary"> clientes </strong>
      van a querer saber más de ti y de lo que

      <strong class="txt-primary"> haces. </strong>
    </h2>
    <p class="fs-6 fs-md-5 fs-lg-4">
      Una página web empresarial permitirá a tus clientes conocer mucho más
      acerca de la misión de la empresa, de quiénes la conforman, sus objetivos,
      sus valores y sus servicios.
    </p>
    <p class="fs-6 fs-md-5 fs-lg-4">
      Recuerda, el propósito de la misma es dar a tus clientes la información
      necesaría relacionado con el actuar de tu organización.
    </p>
  </div>
</section>

<section class="advantages mt-3 d-flex flex-column align-items-center">
  <section
    class="col-12 col-sm-11 col-xl-10 d-flex justify-content-center align-items-center flex-wrap"
  >
    <div
      class="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-1 p-2 p-md-3"
      *ngFor="let item of advantages"
    >
      <div
        class="advantage"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        (click)="handler(item)"
      >
        <figure>
          <ng-lottie class="w-100" [options]="item.image"> </ng-lottie>
        </figure>
        <strong class="text-center">
          {{ item.name }}
        </strong>
      </div>
    </div>
  </section>
</section>
<p-divider></p-divider>

<section class="col-11 col-sm-11 col-lg-10 ps-2 px-md-3 px-lg-4">
  <header class="my-5">
    <h2 class="text-center">
      ¿Te identificas con alguna de las siguientes situaciones?
    </h2>
    <h3 class="text-center"></h3>
  </header>
  <div
    *ngFor="let situation of situations"
    class="situation col-12 col-sm-10 col-xl-8 col-xxl-8"
  >
    <header class="col-12">
      <h4 class="m-0">{{ situation.name }}</h4>
    </header>
    <div
      class="col-12 col-md-7 col-xxl-8 px-3 px-md-5 py-3 px-md-2 d-flex flex-column justify-content-center"
    >
      <p *ngFor="let d of situation.description">
        {{ d }}
      </p>
    </div>
    <figure
      class="col-12 col-md-5 col-xxl-4 m-0 d-flex justify-content-center align-items-end"
    >
      <img
        class="col-8 col-sm-6 col-md-8"
        [src]="situation.image.path"
        [attr.alt]="situation.image.alt"
        srcset=""
      />
    </figure>
    <footer class="col-12">
      {{ situation.aa }}
    </footer>
  </div>
</section>

<section
  class="benefits col-11 col-sm-11 col-lg-10 col-xl-11 ps-2 px-md-3 px-lg-4"
>
  <header class="my-5">
    <h2 class="text-center">Queremos que nuestro servicio sea</h2>
  </header>
  <section class="d-flex flex-wrap w-100">
    <div class="col-12 col-md-6 animate__animated animate__bounceInLeft">
      <span>Rápido</span>
      <p>
        Con nuestras herramientas internas podemos entregarte valor más rápido
      </p>
    </div>
    <div class="col-12 col-md-6 animate__animated animate__bounceInRight">
      <span>Eficiente</span>
      <p>Optimizamos procesos de desarrollo y diseño para tu beneficio.</p>
    </div>
    <div class="col-12 col-md-6 animate__animated animate__bounceInLeft">
      <span>Accesible</span>
      <p>
        No pienses que la tecnología web es un gasto, es más una inversión a
        mediano y largo plazo.
      </p>
    </div>
    <div class="col-12 col-md-6 animate__animated animate__bounceInRight">
      <span>De calidad</span>
      <p>
        Automatizamos verificaciones y despliegues, siendo más precisos y
        excelentes en nuestros productos.
      </p>
    </div>
  </section>
</section>

<section class="col-11 col-sm-11 col-lg-10 ps-2 px-md-3 px-lg-4">
  <header class="my-5">
    <h2 class="text-center">Planes de Soporte</h2>
    <h3 class="text-center"></h3>
  </header>
  <table class="my-5">
    <thead>
      <tr>
        <th></th>
        <th class="px-2">
          <h3>Estándar</h3>
          <p class="m-0">Perfecto para empresas con pocos cambios anualmente</p>
        </th>
        <th class="px-2">
          <h3>Plus</h3>
          <p class="m-0">
            Ideal para empresas con alta generación de contenido
          </p>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Repositorio de código</td>
        <td colspan="2">Sin costo</td>
      </tr>
      <tr>
        <td>Tareas Automáticas</td>
        <td colspan="2">Sin costo</td>
      </tr>
      <tr>
        <td>Contenido</td>
        <td colspan="2">
          <p>
            Carrusel de imágenes, Encabezado con información de la empresa,
            Quiénes somos, Aliados, Servicios, Videos, Pie de página con los
            datos de la empresa, Redes sociales, Información de la empresa,
            Casos de éxito, Botón de pago
          </p>
          Sin costo
        </td>
      </tr>
      <tr>
        <td>Analítica</td>
        <td>No aplica</td>
        <td>Reporte Mensual de Actividad en formato PDF</td>
      </tr>
      <!-- <tr>
        <td>Correo electrónico</td>
        <td colspan="2">Configuración de correos de acuerdo a la demanda</td>
      </tr> -->
      <tr>
        <td>Modificaciones anuales</td>
        <td>6</td>
        <td>15</td>
      </tr>
      <tr>
        <td>Modificaciones adicionales por año</td>
        <td>$ 15.000 por hora</td>
        <td>$ 12.000 por hora</td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td class="empty"></td>
        <td>$ 200.000 anual</td>
        <td>$ 500.000 anual</td>
      </tr>
      <tr>
        <td class="empty"></td>
        <td colspan="2" class="text-start px-2">
          <h3 class="fs-6">A tener en cuenta:</h3>
          <ul>
            <li class="fw-light">
              Hora de trabajo por modificaciones fuera de algún plan:
              <strong> $ 40.000 </strong>
            </li>
            <li class="fw-light">
              Los cambios que no usen en el periodo de un año no serán
              redimibles en el siguiente año de suscripción.
            </li>
          </ul>
        </td>
      </tr>
    </tfoot>
  </table>
</section>

<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div *ngIf="currentAdvantage" class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title text-center fs-5" id="exampleModalLabel">
          {{ currentAdvantage.name }}
        </h1>
      </div>
      <div class="modal-body">
        <div class="w-50 m-auto">
          <ng-lottie class="w-50" [options]="currentAdvantage.image">
          </ng-lottie>
        </div>
        <p-divider></p-divider>
        <p class="py-2 px-1">
          {{ currentAdvantage.description }}
        </p>
      </div>
    </div>
  </div>
</div>
