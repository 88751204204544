

<p-menubar [model]="items">
  <ng-template pTemplate="end">
    <div routerLink="/" class="cursor-pointer d-flex py-sm-0 justify-content-center pe-sm-4 pe-2">
      <figure class="m-0 my-auto mx-2">
        <img
          
          class="logo z-1"
          src="assets/images/logo.png"
          alt="Scifamek logo"
          width="30"
          height="30"
        />
      </figure>
      <h1
        class="company-name animate__animated animate__rubberBand fs-5 m-0 z-3 align-content-center"
      >
        Scifamek
      </h1>
    </div>
  </ng-template>
</p-menubar>
