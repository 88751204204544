<header class="p-2 pt-2 pt-sm-2 mt-2 mt-sm-2">
  <h1 class="my-5 text-center">Herramientas poderosas para tu empresa</h1>
  <p class="text-center fs-4 my-3 my-md-2 px-2 px-sm-4 px-md-5">
    Utiliza nuestras
    <strong class="txt-primary"> librerías </strong>
    para alcanzar tus metas

    <strong class="txt-primary"> empresariales.</strong>
  </p>
</header>

<section>
  <!-- <header class="px-3 text-center">
      <h2>¿Por qué tener una página web?</h2>
    </header> -->
  <div class="pb-3 px-3 px-sm-5 text-center">
    <p>
      Si tienes necesidades puntuales y buscas una plataforma que se integre a
      tu empresa orquestando diversos procesos empresariales, acá tal puedes
      encontrar alguna solución que cumpla con dichos requerimientos.
    </p>
    <p>
      Hay librerías de gratuitas y otras de pago. Revisa la disponibilidad de
      las mismas a continuación.
    </p>
  </div>
</section>

<p-divider></p-divider>

<section class="col-11 col-sm-11 col-lg-10 ps-2 px-md-3 px-lg-4">
  <header class="my-2 py-2">
    <p-selectButton
      [options]="availableStates"
      [(ngModel)]="selectedState"
      (onChange)="filter()"
      optionLabel="display"
    ></p-selectButton>
  </header>

  <ng-template #languages let-component="component">
    <p-chip
      class="mx-1"
      *ngFor="let lang of component.languages"
      [image]="iconsTable[lang.language]"
      [label]="lang.version"
    >
    </p-chip>
  </ng-template>
  <p-accordion>
    <p-accordionTab *ngFor="let component of filteredComponents">
      <ng-template pTemplate="header">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div class="d-flex align-items-center">
            <div class="marker" *ngIf="component.price"></div>
            <h4 class="m-0">{{ component.name }}</h4>
          </div>

          <div class="languages">
            <ng-container
              *ngTemplateOutlet="languages; context: { component: component }"
            ></ng-container>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="languages">
          <ng-container
            *ngTemplateOutlet="languages; context: { component: component }"
          ></ng-container>
        </div>
        <p class="mt-2">
          {{ component.description }}
        </p>
        <ng-container *ngIf="component.languages">
          <p-tabView>
            <p-tabPanel
              *ngFor="let f of component.languages"
              [header]="f.language"
            >
              <ul>
                <li *ngFor="let item of f.items">
                  {{ item }}
                </li>
              </ul>
            </p-tabPanel>
          </p-tabView>
        </ng-container>
        <p-tag *ngIf="component.price">
          {{ component.price | currency : "COP " }}
        </p-tag>
      </ng-template>
    </p-accordionTab>
  </p-accordion>
</section>

<section class="my-5"></section>
