<img #img src="assets/components/header/imagen.webp" alt="Video introductorio de scifamek" srcset="">
<video #video
  loop
  muted
  width="100%"
  poster="assets/components/header/imagen.webp"
>
  <source [src]="currentVideo.url" type="video/mp4" />
  Tu navegador no soporta la etiqueta de video.
</video>
<div class="triangle1"></div>
<div class="title">
  <header class="mx-3 mx-sm-5">
    <h1 class="animate__animated animate__delay-1s animate__pulse">
      IMPULSANDO
    </h1>
    <h1 class="animate__animated animate__pulse animate__delay-2s">
      TU EMPRESA
    </h1>
    <h2
      class="text-white animate__animated animate__delay-1s animate__lightSpeedInLeft"
    >
      con talento, innovación y tecnología
    </h2>
  </header>
</div>
