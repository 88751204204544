<app-header class="w-100"></app-header>

<section id="seccion3" class="specializedLines py-5">
  <header class="w-100 justify-content-center d-flex">
    <h2 class="pb-5 px-3 text-center">
      ¿EN QUÉ SOMOS <strong class="text-primary">BUENOS</strong>?
    </h2>
  </header>

  <div class="row m-0 d-flex justify-content-center">
    <div class="col-12 col-sm-6 col-md-5">
      <h3 class="fs-3 py-1 px-3">Desarrollo de software a medida</h3>
      <p class="px-3 py-4">
        Diseñamos y construimos aplicaciones que se adaptan perfectamente a las
        necesidades de tu negocio, asegurando eficiencia y escalabilidad.
      </p>
    </div>

    <div class="col-12 col-sm-6 col-md-5">
      <h3 class="fs-3 py-1 px-3">Los mejores talentos a tu disposición</h3>
      <p class="px-3 py-4">
        Desde desarrolladores full-stack hasta arquitectos de software, te
        proporcionamos los profesionales que harán realidad tus objetivos
        tecnológicos.
      </p>
    </div>
  </div>
</section>

<section id="seccion1">
  <header class="w-100 justify-content-center d-flex mt-4 mb-2">
    <h2 class="text-center px-3">
      Colaboración<strong class="text-primary"> flexible</strong> a tu medida.
    </h2>
  </header>

  <div
    class="m-auto col-12 col-md-10 col-xxl-8 m-0 d-flex flex-column-reverse flex-sm-row"
  >
    <div class="col-12 col-sm-5 align-items-center d-flex">
      <h2 class="px-2 text-center align-content-center fs-4 pt-2">
        Nuestros <strong class="text-primary">desarrolladores</strong> de
        software se <strong class="text-primary">unen</strong> a tu equipo.
      </h2>
    </div>
    <figure class="col d-flex justify-content-center">
      <img
        width="100%"
        src="../assets/images/animacion_talentos.gif"
        alt="talentos empresas scifamek, outsourcing"
      />
    </figure>
  </div>
  <div class="mx-auto col-md-1 my-3">
    <button class="button-tecnological-talents justify-content-center" 
    (click)="onClickTechnologicalTalents()">Detalles</button>
  </div>

</section>

<section id="seccion2" class="py-4">
  <header class="px-1 px-sm-4 py-4 justify-content-center d-flex">
    <h2 class="pb-3 text-center">
      TRANSFORMAMOS TUS <strong class="text-primary">IDEAS</strong> EN
      SOLUCIONES DIGITALES <strong class="text-primary">ÚNICAS.</strong>
    </h2>
  </header>
  <div class="px-2 px-sm-4">
    <app-our-services></app-our-services>
  </div>
</section>

<section id="seccion4" class="pt-5">
  <header class="w-100 justify-content-center d-flex">
    <h2 class="pb-5">
      ¿CÓMO LO <strong class="text-primary">HACEMOS</strong>?
    </h2>
  </header>

  <div class="col-11 col-md-10 col-xxl-8 m-auto m-0 d-flex flex-column">
    <aside class="col-12 d-flex mb-2 mb-sm-0 flex-row p-0 px-1 align-items-end">
      <figure
        *ngFor="let hwdi of howWeDoIt; let i = index"
        class="my-3 d-flex justify-content-center"
        (click)="handleDevelopmentSteps(hwdi)"
      >
        <ng-lottie
          class="icon"
          [ngClass]="{
            brillante: currentHowWeDoIt
              ? currentHowWeDoIt.icon == hwdi.icon
              : false
          }"
          [options]="hwdi.icon"
        >
        </ng-lottie>
      </figure>
    </aside>
    <ng-container *ngIf="!!currentHowWeDoIt; else noContent">
      <section
        class="col-12 ps-3 ps-sm-4 animated-text"
        [ngClass]="{ animate: isAnimated }"
      >
        <ul class="p-0 my-3 w-100 px-sm-3">
          <li
            class="col-md-11 py-2 w-100"
            *ngFor="let hwdi of currentHowWeDoIt.steps"
          >
            {{ hwdi }}
          </li>
        </ul>
        <p-divider></p-divider>
      </section>
    </ng-container>

    <ng-template #noContent>
      <div
        class="col py-1 py-sm-5 ps-3 ps-sm-4 d-flex flex-column align-items-center justify-content-center"
      >
        <h3 class="py-2 text-primary">¡¡Conoce todos los pasos!!</h3>
        <p>
          Descubre cómo cada etapa se transforma en un eslabón crucial en la
          creación de soluciones innovadoras y efectivas.
        </p>
      </div>
    </ng-template>
  </div>

  <ng-container *ngIf="!!currentHowWeDoIt; else noContent">
    <div class="title-how">
      <h5 class="py-2">{{ currentHowWeDoIt.title }}</h5>
    </div>
  </ng-container>
</section>

<footer
  class="text-white row m-0 text-md-left d-flex justify-content-center pt-3"
>
  <div class="col-12">
    <h4 class="mb-4 text-center">SCIFAMEK S.A.S.</h4>
    <p class="text-center">
      <small>
        Nos especializamos en la provisión de talento altamente calificado y en
        la creación de soluciones de software personalizadas que impulsan la
        innovación y la eficiencia en su empresa.
      </small>
    </p>
  </div>
  <hr class="mb-4" />

  <div class="col-6 col-md-3 d-flex flex-column align-items-center">
    <h5 class="mb-4 font-weight-bold">PRODUCTOS</h5>
    <ul class="d-flex flex-column p-0">
      <a
        role="listitem"
        href="#seccion1"
        class="text-white"
        style="text-decoration: none"
        >Outsourcing TI</a
      >
      <a
        role="listitem"
        href="#seccion2"
        class="text-white"
        style="text-decoration: none"
        >Desarrollo a Medida</a
      >
      <a
        role="listitem"
        href="https://facires.com/home"
        target="_blank"
        class="text-white"
        style="text-decoration: none"
        >Facires</a
      >
    </ul>
  </div>

  <div class="col-6 col-md-3 d-flex flex-column align-items-center">
    <h5 class="text-uppercase mb-4 font-weight-bold">Enlaces Útiles</h5>
    <ul class="d-flex flex-column p-0">
      <a
        role="listitem"
        href="#seccion3"
        class="text-white"
        style="text-decoration: none"
        >En qué somos buenos?</a
      >
      <a
        role="listitem"
        href="#seccion4"
        class="text-white"
        style="text-decoration: none"
        >Como lo hacemos?</a
      >
    </ul>
  </div>

  <div class="col-6 col-md-3 d-flex flex-column align-items-center">
    <h5 class="text-uppercase mb-4 font-weight-bold">Contacto</h5>
    <ul class="p-0">
      <li>Manizales, Colombia</li>
      <li>scifamek&#64;gmail.com</li>
      <li>+57 312 482 54 67</li>
      <li>+57 310 433 17 15</li>
    </ul>
  </div>

  <hr class="mb-4" />

  <div class="row col-md-12 align-items-center">
    <div class="col-md-5 col-lg-8 mx-auto">
      <p class="text-center">
        © 2024 Todos los derechos reservados:
        <a href="#" class="text-white">
          <strong> Scifamek S.A.S.</strong>
        </a>
      </p>
    </div>
  </div>
</footer>
